import React from 'react';

import './Panel.css'

import Photo from '../../Images/photo-4.jpg'

class DesignPanel extends React.Component {

    render() {
        return(
            <div className="panel design" id="design">
                <div className="contain columns">
                    <div className="title">GRAPHIC DESIGN APPRENTICE</div>
                    <p><img src={Photo} alt=""/></p>
                    <p>
                    The Graphic Design Apprentice will be a champion of the Fiends brand! You will work with our Creative Directors to create visual representations of our brand strategy and set a creative vision for Fiends that is not only extraordinary, but that aligns with the overall business and drives growth.
                    </p>
                    <br />
                    <p>
                    We are looking for designers who will guide creative function to bring the brand to life in unique and consistently unparalleled ways. You will own the creative process from concept to production across all marketing media including collateral, digital and print campaigns, photography, video, and events of all sizes. You will be able to develop graphical elements and design experiences for tangible live goods and events as well as mobile & desktop assets. You will develop seasonal campaigns for all avenues, from the website to printed materials, in store visuals and social media. You will design homepage banners, videos and digital content for the website on a weekly basis, including scheduled content for holidays and promotions.
                    </p>
                    
                    <p>
                    You will need to have a strong sense of aesthetic & style and be responsible for delivering effective, appealing, and strategically cohesive creative graphics for websites, promotional emails, email templates, social media graphics, and banner ads.
                    </p>
                    
                    <p>
                    This position is one of a creator. You need to be Virgil meets Ronnie Fieg meets Keynote wizard.
                    </p>
                </div>
            </div>
        )
    }
}

export default DesignPanel
