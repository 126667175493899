import React from 'react';

import './Panel.css'

import Photo from '../../Images/marketing.jpg'

class MarketingPanel extends React.Component {

    render() {
        return(
            <div className="panel marketing" id="marketing">
                <div className="contain columns">
                    <div className="title">MARKETING APPRENTICE</div>
                    <img src={Photo} alt=""/>
                    <p>
                        The strategy apprentice will provide support on creative, marketing, design, and departmental administration. This position will operate directly under the head of the company and will work collaboratively with the rest of the team. You will assist in growing the business through the development of forward-thinking, culturally relevant ideas. Work with the Fiends is fast paced and relies on tight deadlines and last minute production schedules, you’ll have to be quick thinking and versatile.
                    </p>

                    <p>
                        You will be responsible for providing a constant flow of ideas when tasked with brainstorming. You will build concepts in a timely fashion, present and visualize your ideas, and balance multiple tasks at once under the guidance of Fiends team leadership.
                    </p>

                    <p>
                        You will assist in creating presentations for internal and external audiences – articulating ideas clearly through the written and video medium, with photographic and graphic research for designers, mood boards, and mocks.
                    </p>

                    <p>
                        This position is one of a builder. Vision and strategy are key. You need to be Little Finger but with less of an ego so there’s a happier ending.
                    </p>
                </div>
            </div>
        )
    }
}

export default MarketingPanel
