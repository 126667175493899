import React from 'react'
import ReactDOM from 'react-dom'

import 'reset-css'

import './index.css'
import NavBar from './Components/NavBar/NavBar'
import Landing from './Components/Landing/Landing'
import AcademyPanel from './Components/Panel/AcademyPanel'
import MarketingPanel from './Components/Panel/MarketingPanel'
import MultimediaPanel from './Components/Panel/MultimediaPanel'
import DesignPanel from './Components/Panel/DesignPanel'
import FormPanel from './Components/Panel/FormPanel/FormPanel'
import Footer from './Components/Footer/Footer'

ReactDOM.render(
	<>
		<NavBar />
		<Landing />
		<AcademyPanel />
		<DesignPanel />
		<MultimediaPanel />
		<MarketingPanel />
		<FormPanel />
		<Footer />
	</>,
	document.getElementById('root')
)
