import React from 'react';

import './Panel.css'

import Photo from '../../Images/multi.jpg'

class MultimediaPanel extends React.Component {

    render() {
        return(
            <div className="panel multimedia" id="multimedia">
                <div className="contain columns">
                    <div className="title">MULTIMEDIA APPRENTICE</div>

                    <p>
                        <img src={Photo} alt=""/>
                    </p>

                    <p>
                        The Multimedia Apprentice will work directly with our Marketing and Creative Specialists, both on location and in the office. On location, you will be involved in photo shoots for marketing collateral as well as editorial publication. You may also help provide photographic and video coverage of events. You will have the opportunity to assist on video shoots, controlling cameras, assisting with audio, and lighting. You will create a wide variety of short multimedia pieces for use on social media.
                    </p>

                    <p>
                        In the office you will assist with photo and video editing, tagging photos and videos, and uploading completed media assets to the digital asset management system. You will learn about the role the multimedia specialist fills within the marketing department and communications group and the legal aspects of media creation. 
                    </p>

                    <p> 
                        This position is one of a creator. You need to be meme god and pop culture whore. You will be a content farm. 
                    </p>
                    
                    
                </div>
            </div>
        )
    }
}

export default MultimediaPanel
