import React from 'react';
import {Link as ScrollLink} from 'react-scroll'

import './NavBar.css'
import FiendAcademyLogotype from '../../Images/fiends-academy-logotype.png'

class NavBar extends React.Component {
    render() {
        return(
            <div class="header">
                <div className="logo">
                    <img src={FiendAcademyLogotype} alt=""/>
                </div>
                <div className="nav-bar">
                    <ScrollLink to="design" smooth={true}>Design</ScrollLink>
                    <ScrollLink to="multimedia" smooth={true}>Multimedia</ScrollLink>
                    <ScrollLink to="marketing" smooth={true}>Strategy</ScrollLink>
                    <ScrollLink to="apply" smooth={true}>Apply</ScrollLink>
                </div>
            </div>
        )
    }
}

export default NavBar
