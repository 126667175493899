import React from 'react';

import './Panel.css'

import Photo from '../../Images/photo-1.jpg'

class AcademyPanel extends React.Component {

    render() {
        return(
            <div className="panel academy" id="academy">
                <div className="contain">
                    <div className="title">FIENDS ACADEMY</div>
                    <p>
                        <img src={Photo} alt=""/>
                    </p>

                    <p>
                        Welcome. We are so glad we made it here! From the first time we made a dollar from our creative ventures, it was abundantly clear that we wanted to create an apprenticeship program aka Fiends Academy. We want it to be a very special program— one where young people engage deeply in practical real world experience while learning and preparing for life in a changing industry while being guided by and shaped by our family of Fiends.
                    </p>

                    <p>
                        A talented and dedicated collective provides apprentices with a dynamic, flexible, and robust program. Through a range of creative pathways, apprentices are challenged to push beyond the limits of their intellect to broaden their perspectives and build skills and habits of mind that will serve them well past their time with the Fiends.
                    </p>

                    <p>
                        Our goal is to open up our creative process to select candidates. We don’t look at our program as a conventional internship opportunity. There is no clear path to success, there are only the paths that one forges for themselves. The Fiends Academy is an entry point to create your own path. Our apprentices will assist with new business opportunities and help execute on the production side through their work alongside the creative, strategic and design teams. They will get hands on training from the members of the team that they connect with, and get a firsthand look into how we function as a media company on a day-to-day basis. They will develop an understanding of the Fiends voice — what we stand for, what we create, and who we want to talk to — and ideate with this voice in mind.

                    </p>
                </div>
            </div>
        )
    }
}

export default AcademyPanel
