import React from 'react';

import './Footer.css'

import WaxStamp from '../../Images/fiends-academy-logo.png'

class Footer extends React.Component {
    render() {
        return(
            <div className="footer">
                <div className="socials">

                </div>

                <img className="wax-stamp" src={WaxStamp} alt=""/>

                <div className="copyright">Super Mookin Fiends, LLC &copy; {new Date().getFullYear()}</div>
            </div>
        )
    }
}

export default Footer