import React from 'react';
import Typed from 'react-typed';
import {Link as ScrollLink} from 'react-scroll'
import Tilt from 'react-tilt'

import './Landing.css'

import FiendsAcademyLogo from '../../Images/fiends-academy-logo.png'
import DownArrow from '../../Images/down-arrow.png'

class Landing extends React.Component {

    render() {
        return(
            <div className="landing-wrapper">
                <Tilt className="Tilt" options={{ max: 10, reverse: true }} style={{ height: "100%", width: "100%" }}>
                    <div className="landing">
                        <img src={FiendsAcademyLogo} alt="" className="logo rotating"/>
                        <h1>
                            <Typed
                                strings={['Strong Desires, <br /> Good Intentions.']}
                                typeSpeed={40}
                            />
                        </h1>
                        <h2>
                            The Fiends were built by Bas and a group of his curious friends with special skills that will translate well in the creative world. Bas and The Fiends are looking to give real life experience to a select few apprentices. Apply below. 

                            {/* <Typed
                                strings={['The team is growing. Bas is on the search for a limited amount of Apprentices just like you who possess special skills in modern marketing. Apply below.']}
                                typeSpeed={20}
                            /> */}
                        </h2>

                        <ScrollLink to="academy" smooth={true} className="down-arrow bounce-7">
                            <img src={DownArrow} width="20px" alt=""/>
                        </ScrollLink>
                        {/* <div className="down-arrow bounce-7">
                        </div> */}
                    </div>
                </Tilt>
            </div>
        )
    }
}

export default Landing
