import React from 'react'

import '../Panel.css'
import './FormPanel.css'

class FormPanel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            email: '',
            phone: '',
            instagram: '',
            validated: true
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.fileInput = React.createRef()
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = (e) => {
        var validation = this.state.name !== '' && this.state.email !== '' && this.state.phone !== '' && this.fileInput.current.files.length
        if (!validation) {
            e.preventDefault()
            window.alert('You must fill all the required (*) fields before submitting your application.')
        }
    }

    render() {
        return(
            <div className="panel form" id="apply" onSubmit={this.handleSubmit}>
                <div className="contain">
                    <div className="title">The Application</div>

                    <p>
                        <form action="https://mailthis.to/iamcoreyg+fiends@gmail.com" method="POST" encType="multipart/form-data">
                        <label htmlFor="name">Your Name *</label>
                        <input type="text" name="name" placeholder="XXXXX XXXXXXXXXX" value={this.state.name} onChange={this.handleChange}  />
                        
                        <label htmlFor="email">Your Email *</label>
                        <input type="email" name="email" placeholder="XX@XXX.X" value={this.state.email} onChange={this.handleChange}  />
                        
                        <label htmlFor="phone">Your Phone *</label>
                        <input type="number" name="phone" placeholder="XXX XXX-XXXX" value={this.state.phone} onChange={this.handleChange}  />
                        
                        <label htmlFor="instagram">Your Instagram</label>
                        <input type="text" name="instagram" placeholder="@XXXX" value={this.state.instagram} onChange={this.handleChange}  />
                        
                        <label htmlFor="resume">Your Resume .pdf *</label>
                        <input type="file" accept="application/pdf" name="file" ref={this.fileInput} />
                        
                        <input type="hidden"name="_confirmation" value="Thank you for your submission. Your resume will be reviewed."></input>
                        
                        <input class={this.state.validated ? 'active' : ''} type="submit" value="Submit Your Application" onChange={this.handleChange} />
                        </form>
                    </p>
                </div>
            </div>
        )
    }
}

export default FormPanel